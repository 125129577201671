<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
    name: 'App',
    provide: function () {
        return {
            app: this,
            formulario: '',
        }
    },
    async created() {
        this.$store.dispatch('app/getLocation');
    },
}
</script>
